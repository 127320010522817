import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Header } from "../components";

import Horoscopes from "../apps-data/horoscopes";

import "./index.css";

const InfinityApp = (props) => {
  const [json, getJSON] = useState({});

  useEffect(() => {
    const currentScene = parseInt(props.scene, 10);
    const prediction = Horoscopes.latest(currentScene);
    getJSON(prediction);
  }, [json]);

  return (
    <div className="app--infinity">
      <Header logo={false} dark={false} headline={"Infinity"} params={true} />
      <div className="app--body">
        <h1>Advice</h1>
        <h2>
          <span role="img" aria-label="crystal ball">
            🔮
          </span>
        </h2>
        <p>{json.message}</p>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { storyReducer } = state;
  return storyReducer;
}

export default connect(mapStateToProps)(InfinityApp);
