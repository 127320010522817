import React from "react";
import { Switch, Route } from "react-router-dom";

// VIEWS
import Scene from "./views/scene";
import StartPage from "./views/start";
import PhoneScreen from "./views/phoneScreen";

// APPS
import AllSeeing from "./views/apps/allSeeing";
import MapApp from "./views/apps/map";
import Calendar from "./views/apps/calendar";
import Photos from "./views/apps/photos";

import Questions from "./views/apps/questions";
import Food from "./views/apps/food";
import Videos from "./views/apps/videos";

import Notes from "./views/apps/notes";
import InfinityApp from "./views/apps/infinity";
import Qliqk from "./views/apps/qliqk";

import Spacebook from "./views/apps/spacebook";
import SpacebookFriends from "./views/apps/spacebook/friends";
import SpacebookProfile from "./views/apps/spacebook/profile";
import SpacebookSearch from "./views/apps/spacebook/search";

import CreatePeople from "./create-people/CreatePeople";

import Texts from "./views/apps/texts";

const Routes = () => (
  <main>
    <Switch>
      <Route exact path="/" render={(props) => <StartPage {...props} />} />
      <Route
        exact
        path="/phone"
        render={(props) => <PhoneScreen {...props} />}
      />
      <Route path="/scene/:sceneId" render={(props) => <Scene {...props} />} />

      <Route
        path="/create-people"
        render={(props) => <CreatePeople {...props} />}
      />

      {/* Apps */}
      <Route path={"/future"} render={(props) => <AllSeeing {...props} />} />
      <Route path={"/map"} render={(props) => <MapApp {...props} />} />
      <Route path={"/calendar"} render={(props) => <Calendar {...props} />} />

      <Route
        path={"/photos/:thisScene/:nextScene"}
        render={(props) => <Photos {...props} />}
      />
      <Route
        path={"/photos/:thisScene"}
        render={(props) => <Photos {...props} />}
      />
      <Route path={"/photos"} render={(props) => <Photos {...props} />} />

      <Route
        path={"/questions/:pageid/:searchterm"}
        render={(props) => <Questions {...props} />}
      />
      <Route
        path={"/questions/:pageid"}
        render={(props) => <Questions {...props} />}
      />
      <Route path={"/food"} render={(props) => <Food {...props} />} />
      <Route path={"/videos"} render={(props) => <Videos {...props} />} />

      <Route
        path={"/notes/:thisScene/:nextScene"}
        render={(props) => <Notes {...props} />}
      />
      <Route
        path={"/notes/:thisScene"}
        render={(props) => <Notes {...props} />}
      />
      <Route path={"/notes"} render={(props) => <Notes {...props} />} />

      <Route
        path={"/infinity/:thisScene"}
        render={(props) => <InfinityApp {...props} />}
      />
      <Route
        path={"/infinity"}
        render={(props) => <InfinityApp {...props} />}
      />

      <Route path={"/qliqk"} render={(props) => <Qliqk {...props} />} />

      <Route
        path={"/spacebook/user/:userId"}
        render={(props) => <SpacebookProfile {...props} />}
      />
      <Route
        path={"/spacebook/search"}
        render={(props) => <SpacebookSearch {...props} />}
      />
      <Route
        path={"/spacebook/friends"}
        render={(props) => <SpacebookFriends {...props} />}
      />
      <Route path={"/spacebook"} render={(props) => <Spacebook {...props} />} />

      <Route
        path={"/texts/:thisScene/:nextScene"}
        render={(props) => <Texts {...props} />}
      />
      <Route
        path={"/texts/:thisScene"}
        render={(props) => <Texts {...props} />}
      />
      <Route path={"/texts"} render={(props) => <Texts {...props} />} />
    </Switch>
  </main>
);

export default Routes;
