const jobs = [
  "Art Director",
  "Prop Maker",
  "Sculptor",
  "Prop Master",
  "Scenic Artist",
  "Painter",
  "Set Construction Coordinator",
  "Builder",
  "Set Decorator",
  "Dresser",
  "Storyboard Artist",
  "Director of Photography",
  "Camera Assistant",
  "1st AC",
  "2nd AC",
  "Loader",
  "Camera Operator",
  "Camera Operator (Aerial)",
  "Data Wrangling",
  "Digital Imaging Technician",
  "Director of Photography",
  "Lighting Designer",
  "Photographer",
  "Production Stills",
  "Steadicam Operator",
  "Videographer",
  "Assistant Food Stylist",
  "Craft Service",
  "Food Stylist",
  "Best Boy",
  "Electrician",
  "Gaffer",
  "Grip",
  "Key Grip",
  "Location Manager",
  "Locksmith",
  "Location Scout",
  "Costume Designer",
  "Hair Stylist",
  "Makeup Artist",
  "Special FX Makeup",
  "Wardrobe Stylist",
  "Assistant Director",
  "2nd AD",
  "Director",
  "Line Producer",
  "Producer",
  "Production Assistant",
  "Production Coordinator",
  "Production Designer",
  "Production Manager",
  "Production Secretary",
  "Script Supervisor",
  "Teleprompter Operator",
  "Video Assist Operator",
  "Focus Puller",
  "Boom Operator",
  "Utility",
  "Sound Mixer",
  "Pyro Technician",
  "Explosives",
  "Special Effects Coordinator",
  "Special Effects Technician",
  "Stunt Coordinator",
  "Gang Boss",
  "Transportation Driver",
  "accountant",
  "actor",
  "air steward",
  "animator",
  "architect",
  "assistant",
  "Anesthesiologist",
  "author",
  "baker",
  "biologist",
  "builder",
  "butcher",
  "Automotive mechanic",
  "Janitor",
  "Executive secretary",
  "career counselor",
  "caretaker",
  "chef",
  "civil servant",
  "clerk",
  "comic book writer",
  "company director",
  "computer programmer",
  "cook",
  "decorator",
  "dentist",
  "designer",
  "diplomat",
  "director",
  "doctor",
  "economist",
  "editor",
  "electrician",
  "engineer",
  "executive",
  "farmer",
  "film director",
  "fisherman",
  "fishmonger",
  "flight attendant",
  "garbage man",
  "geologist",
  "hairdresser",
  "teacher",
  "jeweler",
  "Criminal investigator",
  "journalist",
  "judge",
  "juggler",
  "File clerk",
  "lawyer",
  "Cardiovascular technician",
  "Fish and game warden",
  "Film and video editor",
  "Funeral director",
  "Administrative assistant",
  "Cashier",
  "Private detective",
  "Mediator",
  "Hazardous materials removal worker",
  "Roofer",
  "Court reporter",
  "Construction worker",
  "Matte painter",
  "Network systems administrator",
  "Bellhop",
  "Market research analyst",
  "lecturer",
  "lexicographer",
  "library assistant",
  "magician",
  "makeup artist",
  "manager",
  "miner",
  "musician",
  "School bus driver",
  "Leather worker",
  "Safe repairer",
  "nurse",
  "optician",
  "painter",
  "Probation officer",
  "personal assistant",
  "Fire investigator",
  "Landscape architect",
  "pilot",
  "plumber",
  "Software developer",
  "Curator",
  "Crossing guard",
  "Real estate broker",
  "Surveying technician",
  "Personal care aide",
  "Animal control worker",
  "police officer",
  "politician",
  "porter",
  "printer",
  "prison officer",
  "Highway Maintenance Worker",
  "Proofreader",
  "warden",
  "professional gambler",
  "puppeteer",
  "receptionist",
  "sailor",
  "Occupational Therapist",
  "Exo-Biologist",
  "Cryptozoologist",
  "Podiatrist",
  "salesperson",
  "scientist",
  "Animal trainer",
  "Firefighter",
  "HVAC Repair",
  "Insurance claims clerk",
  "Ambulance driver",
  "Substance Abuse Counselor",
  "Property manager",
  "Priest",
  "Behavioral disorder counselor",
  "Legal secretary",
  "Mail clerk",
  "Chauffeur",
  "Athlete",
  "Football Player",
  "Sculptor",
  "Orderly",
  "EMT",
  "secretary",
  "shop assistant",
  "sign language Interpreter",
  "singer",
  "soldier",
  "solicitor",
  "surgeon",
  "tailor",
  "telephone operator",
  "translator",
  "travel agent",
  "trucker",
  "TV cameraman",
  "Television Personality",
  "Radio Personality",
  "Veterinarian",
  "waiter",
  "waitress",
  "web designer",
  "writer",
  "hostess",
  "maitre d'",
  "screenwriter",
  "Show Runner",
  "ADR Recordist",
  "News Anchor",
  "Folklorist",
  "Occultist",
  "Rare Book Collector",
  "Archivist",
  "Parapsychologist",
  "Private Investigator",
  "Executive Administrative Assistant",
  "Fashion Designer",
  "Housekeeper",
  "Funeral Service Manager",
  "Television announcer",
  "Exhibit Designer",
  "Weapons Master",
  "Rigger",
  "Shampooer",
  "Insurance underwriter",
  "Bartender",
  "Dishwasher",
  "Family practitioner",
  "Camera repairer",
  "Registered nurse",
  "Chemist",
  "Atmospheric scientist",
  "Fabricator",
  "Auto damage insurance appraiser",
  "Bike Messenger",
  "Groundskeeper",
  "Embalmer",
  "Zoologist",
  "Religion teacher",
  "Gaming cage worker",
  "Anthropologist",
  "Professor of Occult Literature",
  "Exhibit designer",
  "Upholsterer",
  "Jeweler",
  "Psychiatrist",
  "Ticket agent",
  "Carpenter",
  "Forensic science technician",
  "School psychologist",
  "Model",
  "Mathematician",
  "Fitness Instructor",
  "Construction Coordinator",
  "Radio announcer",
  "Cosmetologist",
  "Reporter",
  "Sound engineering technician",
  "Sociologist",
  "Sailor",
  "Cosmetologist",
  "Public relations specialist",
  "Pharmacist",
  "Urban planner",
  "Dietitian",
  "Highway maintenance worker",
  "Counselor",
  "Chiropractor",
  "Excavating operator",
  "Obstetrician",
  "Sales manager",
  "Taxi driver",
  "Maxillofacial surgeon",
  "Meter reader",
  "Hairstylist",
  "Motion picture projectionist",
  "Bookkeeper",
  "Music director",
  "Chaplain",
  "Choir Director",
  "Concierge",
  "Mortician",
  "Librarian",
  "Personal chef",
  "Manicurist",
  "Cartographer",
  "Rare Books Dealer",
  "Antiquarian",
  "Radiologist",
  "Choreographer",
  "Security guard",
  "Loan officer"
];

export default jobs;
