import React from "react";

import Icon from "../icon/Icon";

import "./list-styles.css";

const List = ({ color, list, switchNote }) => {
  let searchResults = list.map((result, i) => (
    <li className={`item--list item-color-${color}`} key={`result.id-${i}`}>
      <div className={"list--link"} onClick={() => switchNote(result.id)}>
        <span className={"list--title"}>{result.title || result.id}</span>
        <span className={"list--date"}>
          {result.date} &ndash; {result.time}
        </span>
        <span className={"list--excerpt"}>
          {result.excerpt || result.messages[0].text}
          <Icon name="carat-right" height={12} width={12} />
        </span>
      </div>
    </li>
  ));

  return <ul className="list--results">{searchResults}</ul>;
};

export default List;
