import React from "react";

import Base from "./Base";
import Defs from "./Defs";
import Eyes from "./Eyes";
import Hair from "./Hair";
import Mouth from "./Mouth";
import Nose from "./Nose";

const Avatar = ({ classes, colorScheme }) => {
  return (
    <svg width="378px" height="378px" viewBox="0 0 378 378" className={classes}>
      <Defs type={colorScheme.type} />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <Base colorScheme={colorScheme} />
        <Hair colorScheme={colorScheme} />
        <Eyes colorScheme={colorScheme} />
        <Mouth colorScheme={colorScheme} />
        <Nose colorScheme={colorScheme} />
      </g>
    </svg>
  );
};

export default Avatar;
