// A simple data API that will be used to get the data for our components.
const SceneArray = {
  articles: [
    {
      id: "banana",
      content:
        "<p>Bananas are delicious and yellow. Monkeys love eating them.</p>"
    },
    {
      id: "carrie-swanson",
      content: "<p>Carrie Swanson is an actress.</p>"
    },
    {
      id: "doppelgänger",
      content:
        "<p>It's been said that seeing your doppelgänger is a harbinger of death. Percy Bysshe Shelley saw his doppelgänger soon before his death.</p>"
    },
    {
      id: "monkey",
      content: "Monkeys eat Bananas."
    },
    {
      id: "fetch",
      content:
        "<p>The fetch is described as an exact, spectral double of a living human, whose appearance is regarded as ominous. As such, it is similar to the Germanic doppelgänger, and to some conceptions of the British wraith.</p>"
    },
    {
      id: "ankou",
      content:
        "<p>The Ankou is the henchman of Death and he is also known as the grave yard watcher, they said that he protects the graveyard and the souls around it for some unknown reason and he collects the lost souls on his land. The last dead of the year, in each parish, becomes the Ankou of his parish for all of the following year.</p>"
    }
  ],
  get: function(id) {
    const isMatch = element => element.id.toString() === id.toString();
    return this.articles.find(isMatch);
  }
};

export default SceneArray;
