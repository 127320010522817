import React from "react";
import { useDispatch } from "react-redux";
import { returnToScene } from "../../../redux/actions";
import { Link } from "react-router-dom";

const createLinks = (text, i) => {
  const dispatch = useDispatch();
  if (text.includes("]")) {
    const part2 = text.split("]");
    const part3 = part2[1];
    const openApp = part2[0];
    const link = openApp.split("link:")[1].split(" ")[0];
    const linkText = openApp.split("text:")[1];

    return (
      <span key={`span-${i}`}>
        <Link
          className={"button--apps"}
          onClick={() => dispatch(returnToScene(true))}
          to={`/${link}`}
        >
          {linkText}
        </Link>
        {part3}
      </span>
    );
  }

  return <span key={`span-${i}`}>{text}</span>;
};

const parseText = (text) => {
  const paragraphs = text.split("\n\n");
  const finalText = paragraphs.map((p, i) => {
    const parts = p.split("[");
    return (
      <p key={`par-${i}`}>{parts.map((part, x) => createLinks(part, x))}</p>
    );
  });

  return <div key="cool">{finalText}</div>;
};

const Content = ({ author, text }) => (
  <div className="story">
    {parseText(text)}
    {author && <p className="author">&ndash; {author}</p>}
  </div>
);

export default Content;
