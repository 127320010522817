import React from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "./index";
import Logo from "../images/logo@2x.png";

const SpacebookHeader = () => {
  return (
    <div className="spacebook-header">
      <Link className="qlick-logo" to={`/spacebook`}>
        <img src={Logo} alt="LOGO" />
      </Link>
      <Sidebar />
    </div>
  );
};

export default SpacebookHeader;
