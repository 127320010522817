const PostsArray = {
  posts: [
    {
      user: "devin-646",
      message:
        "What some people call my resting bitch face, I call a cultivated blankness.",
      hashtags: "#complex #NotMyProblem #ModellingLife #truth",
      scene: 0,
    },
    {
      user: "tyGuyTuber",
      message: "Time to hit the gym. Get in a ittle cardio",
      hashtags: "#GetAPump #GymRat #LiveToPump #Workout",
      scene: 0,
    },
    {
      user: "devin-646",
      message:
        "That feeling when #SkeetUlrich and @NeveCampbell put in an appearance at your eighth birthday party.",
      hashtags: "#tbt #HappyBirthdayToMe #Celebrate #90s #NeveCampbell",
      scene: 0,
    },
    {
      user: "tyGuyTuber",
      message: "Checkout my latest video! yt.com/v367bw83n",
      hashtags: "#LifeOnTheWeb",
      scene: 0,
    },
  ],
  all: function () {
    return this.posts;
  },
  recent: function (currentScene) {
    const isMatch = (element) => element.scene - currentScene <= 1;
    return this.posts.filter(isMatch);
  },
  userPosts: function (userId) {
    const isMatch = (element) => `${element.user}` === userId;
    return this.posts.filter(isMatch);
  },
};

export default PostsArray;
