const SceneArray = {
  questions: [
    {
      id: "doppelganger fetch double twin sibling ankou fetch",
      results: [
        {
          title: "Doppelgänger",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt…",
          webview: "/questions/doppelgänger"
        },
        {
          title: "Fetch",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt…",
          webview: "/questions/fetch"
        },
        {
          title: "Ankou",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt…",
          webview: "/questions/ankou"
        }
      ]
    },
    {
      id: "banana monkey tree ape gorilla",
      results: [
        {
          title: "Banana",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt…",
          webview: "/questions/banana"
        },
        {
          title: "Monkey",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt…",
          webview: "/questions/monkey"
        },
        {
          title: "Tree",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt…",
          webview: "/questions/tree"
        }
      ]
    },
    {
      id: "carrie swanson",
      results: [
        {
          title: "Carrie Swanson's Profile on Spacebook",
          text: "Carrie Swanson: Actress",
          webview: "/spacebook/user/4"
        },
        {
          title: "Carrie Swanson",
          text: "Carrie Swanson is an actress",
          webview: "/questions/carrie-swanson"
        }
      ]
    },
    {
      id: "the lion's cage performance art",
      results: [
        {
          title: "The Lion's Cage",
          text: "Performance art",
          webview: "/questions/external/lionscage"
        }
      ]
    }
  ],
  all: function() {
    return this.scenes;
  },
  get: function(id) {
    let myID = id.replace(/s$/, "");
    let results = this.questions.find(element => {
      return element.id.toString().includes(myID.toString());
    });
    return results;
  }
};

export default SceneArray;
