import React, { useState } from "react";
import { Link } from "react-router-dom";

import AppWrapper from "../components/wrapper";
import { SpacebookHeader, UserImage } from "./elements";
import { debounce } from "../../../utils";

import CharacterArray from "../apps-data/characters";

import "./index.css";

const SpacebookSearch = (props) => {
  const [filteredCharacters, updateCharacters] = useState([]);

  const getFilteredCharacters = (str) => {
    const updatedList = CharacterArray.filterList(str, "name");
    updateCharacters(updatedList);
  };

  const debounced = debounce((str) => getFilteredCharacters(str), 500);

  const updateText = (e) => {
    if (e.target.value.length >= 3) {
      debounced(e.target.value);
    } else {
      updateCharacters([]);
    }
  };

  return (
    <AppWrapper
      appType="spacebook"
      dark={false}
      headline={""}
      params={props.location}
    >
      <React.Fragment>
        <SpacebookHeader />
        <div>
          <section className="feed">
            <div className="cards">
              <div className="space-search">
                <label htmlFor="search">Search</label>
                <input
                  id="search"
                  type="text"
                  onChange={(e) => updateText(e)}
                />
              </div>
              {filteredCharacters.map((character, i) => {
                return (
                  <div className="card" key={i}>
                    <div className="card__signature">
                      <Link to={`/spacebook/user/${character.id}`}>
                        <UserImage data={character} />
                        <span className="card__name">{character.name}</span>
                      </Link>
                      <p>{character.tagline}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </React.Fragment>
    </AppWrapper>
  );
};
export default SpacebookSearch;
