import React from "react";

const Defs = ({ type }) => {
  if (type === "human") {
    return (
      <defs>
        <circle id="path-1" cx="189" cy="189" r="189"></circle>
        <path
          d="M15,21 C23.836556,21 32,17.836556 32,9 C32,4.0019858 23.2647934,0.0924223821 16,0.0924223821 C8.7352066,0.0924223821 0,5.01051748 0,9 C0,12.9894825 6.163444,21 15,21 Z"
          id="path-3"
        ></path>
        <path
          d="M15,21 C23.836556,21 32,17.836556 32,9 C32,4.0019858 23.2647934,0.0924223821 16,0.0924223821 C8.7352066,0.0924223821 0,5.01051748 0,9 C0,12.9894825 6.163444,21 15,21 Z"
          id="path-5"
        ></path>
        <path
          d="M15,21 C23.836556,21 32,17.836556 32,9 C32,4.0019858 23.2647934,0.0924223821 16,0.0924223821 C8.7352066,0.0924223821 0,5.01051748 0,9 C0,12.9894825 6.163444,21 15,21 Z"
          id="path-7"
        ></path>
        <path
          d="M15,21 C23.836556,21 32,17.836556 32,9 C32,4.0019858 23.2647934,0.0924223821 16,0.0924223821 C8.7352066,0.0924223821 0,5.01051748 0,9 C0,12.9894825 6.163444,21 15,21 Z"
          id="path-9"
        ></path>
      </defs>
    );
  }
  return (
    <defs>
      <circle id="path-1" cx="189" cy="189" r="189"></circle>
    </defs>
  );
};

export default Defs;
