import Prologue from "./story_chapters/prologue";
import Scene1 from "./story_chapters/act1/scene1";
import Scene2 from "./story_chapters/act1/scene2";
import Scene3 from "./story_chapters/act1/scene3";

// A simple data API that will be used to get the data for our components.
const SceneArray = {
  scenes: [...Prologue, ...Scene1, ...Scene2, ...Scene3],
  all: function () {
    return this.scenes;
  },
  get: function (id) {
    const isMatch = (element) => element.id.toString() === id.toString();
    return this.scenes.find(isMatch);
  },
};

export default SceneArray;
