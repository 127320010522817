export const ADD_PREVIOUS_SCENE = "ADD_PREVIOUS_SCENE";
export const FRESH_START = "FRESH_START";
export const LOGIN = "LOGIN";
export const NOTES = "NOTES";
export const QUESTION = "QUESTION";
export const REMOVE_PREVIOUS_SCENE = "REMOVE_PREVIOUS_SCENE";
export const RETURN_TO_SCENE = "RETURN_TO_SCENE";
export const SCENE = "SCENE";
export const TEXTS = "TEXTS";
export const REFRESH = "REFRESH";
