import React, { useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PostArray from "../apps-data/posts";
import AppWrapper from "../components/wrapper";
import { PostsList, SpacebookHeader } from "./elements";
import "./index.css";

const Spacebook = ({ location }) => {
  const currentScene = useSelector(
    (storyReducer) => storyReducer.storyReducer.scene
  );
  const [posts, setPosts] = useState(PostArray.recent(currentScene));

  return (
    <AppWrapper
      appType="spacebook"
      dark={false}
      headline={""}
      params={location}
    >
      <React.Fragment>
        <SpacebookHeader />
        <div>
          <section className="feed">
            <PostsList posts={posts} />
          </section>
        </div>
      </React.Fragment>
    </AppWrapper>
  );
};

export default withRouter(Spacebook);
