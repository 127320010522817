import React from 'react';

import '../App.css';

import jobs from './jobs';
import boysNames from './top-1000-boy-names';
import girlsNames from './top-1000-girl-names';
import lastNames from './top-1000-lastnames';
import {
  bgColors,
  shirt,
  hairColor,
  skinColor1,
  skinColor2,
  eyeColor,
  hairType,
  mouth
} from './colors';

const capitalize = str => {
  const strArray = str.split(' ');
  return strArray
    .map(
      str =>
        str
          .toLowerCase()
          .charAt(0)
          .toUpperCase() + str.toLowerCase().substring(1)
    )
    .join(' ');
};

const PersonList = ({ end, idKey, randomize }) =>
  lastNames.slice(0, end).map((lastName, i) => {
    const isEven = i % 2 === 0;
    const num = Math.floor(i / 2);
    let firstName = isEven ? boysNames[num] : girlsNames[num];
    const job = jobs[i % jobs.length];

    if (randomize) {
      firstName = isEven
        ? boysNames[Math.floor(Math.random() * boysNames.length)]
        : girlsNames[Math.floor(Math.random() * girlsNames.length)];
    }

    const handle = `${capitalize(firstName)}${capitalize(lastName)}`;

    let skinNumber = Math.floor(Math.random() * (skinColor1.length - 3));
    skinNumber = skinNumber + 2;

    const hairSelection = isEven
      ? hairType[Math.floor(Math.random() * 3)]
      : hairType[Math.floor(Math.random() * (hairType.length - 2) + 2)];

    let colorScheme = {
      type: 'human',
      circleBackground: bgColors[Math.floor(Math.random() * bgColors.length)],
      shirt: shirt[Math.floor(Math.random() * shirt.length)],
      skin: skinNumber <= 4 ? skinColor1[skinNumber] : skinColor2[skinNumber],
      hair: hairColor[Math.floor(Math.random() * skinNumber)],
      nose:
        skinNumber <= 4
          ? skinColor1[skinNumber - 1]
          : skinColor2[skinNumber - 1],
      eye: eyeColor[Math.floor(Math.random() * eyeColor.length)],
      hairType: hairSelection,
      mouth: mouth[skinNumber]
    };

    return (
      <Person
        key={handle}
        id={idKey + i}
        firstName={firstName}
        lastName={capitalize(lastName)}
        name={`${firstName} ${capitalize(lastName)}`}
        handle={handle}
        job={capitalize(job)}
        followerCount={Math.floor(Math.random() * 1500)}
        followingCount={Math.floor(Math.random() * 2000)}
        colorScheme={colorScheme}
      />
    );
  });

const Person = ({
  id,
  firstName,
  lastName,
  name,
  handle,
  job,
  followerCount,
  followingCount,
  colorScheme
}) => (
  <pre>
    {'{'}
    <br /> id: {'"'}
    {id}
    {'"'},
    <br /> firstName: {'"'}
    {firstName}
    {'"'},
    <br /> lastName: {'"'}
    {lastName}
    {'"'},
    <br /> name: {'"'}
    {name}
    {'"'},
    <br /> handle: {'"'}
    {handle}
    {'"'},
    <br /> job: {'"'}
    {job}
    {'"'},
    <br /> tagline: {'"'}
    {'"'},
    <br /> followerCount: {followerCount},
    <br /> followingCount: {followingCount},
    <br /> friend: false,
    <br /> colorScheme: {'{'}
    <br /> {'   '}type: {'"'}
    {colorScheme.type}
    {'"'},
    <br /> {'   '}circleBackground: {'"'}
    {colorScheme.circleBackground}
    {'"'},
    <br /> {'   '}shirt: {'"'}
    {colorScheme.shirt}
    {'"'},
    <br /> {'   '}skin: {'"'}
    {colorScheme.skin}
    {'"'},
    <br /> {'   '}hair: {'"'}
    {colorScheme.hair}
    {'"'},
    <br /> {'   '}hairType: {'"'}
    {colorScheme.hairType}
    {'"'},
    <br /> {'   '}nose: {'"'}
    {colorScheme.nose}
    {'"'},
    <br /> {'   '}eye: {'"'}
    {colorScheme.eye}
    {'"'},
    <br /> {'   '}mouth: {'"'}
    {colorScheme.mouth}
    {'"'},
    <br />
    {' },'}
    <br />
    {'},'}
  </pre>
);

const CreatePeople = () => (
  <div>
    <PersonList idKey="id-0-" end={lastNames.length} randomize={false} />
    <PersonList idKey="id-1-" end={lastNames.length / 4} randomize={true} />
    <PersonList idKey="id-2-" end={lastNames.length / 4} randomize={true} />
    <PersonList idKey="id-3-" end={125} randomize={true} />
    <PersonList idKey="id-4-" end={75} randomize={true} />
    <PersonList idKey="id-5-" end={50} randomize={true} />
    <PersonList idKey="id-6-" end={25} randomize={true} />
    <PersonList idKey="id-7-" end={25} randomize={true} />
  </div>
);

export default CreatePeople;
