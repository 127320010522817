import React from "react";

import { Header } from "../components";
import Search from "./search/index";
import Article from "./article";

import "./index.css";

const Page = ({ page, searchTerm }) => {
  if (page !== "search") {
    return <Article article={page} searchTerm={searchTerm} />;
  } else {
    return <Search searchTerm={searchTerm} />;
  }
};

const Questions = (props) => {
  const page = props.match.params.pageid;
  const searchTerm = props.match.params.searchterm || "";
  return (
    <div className="app--questions">
      <Header logo={false} dark={false} headline={""} params={true} />
      <Page page={page} searchTerm={searchTerm} />
    </div>
  );
};

export default Questions;
