import React from "react";
import { Link } from "react-router-dom";
import SceneArray from "../../../data/murmur-ritual";

const Links = ({ links }) => (
  <div>
    {links.map((link) => {
      const scene = SceneArray.get(link);
      const text = scene ? scene.link_text || scene.headline : null;
      const url =
        scene && "isApp" in scene && scene.isApp === true
          ? `${scene.links[0]}/${link}/${scene.links[1]}`
          : `/scene/${link}`;

      if (scene) {
        return (
          <Link key={link} to={url}>
            {text}
          </Link>
        );
      }

      return "";
    })}
  </div>
);

export default Links;
