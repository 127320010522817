import React from "react";
import { Header } from "../components";

import "./index.css";

import Map from "./images/los-angeles.png";

const Maps = (props) => {
  return (
    <div className="app--maps">
      <Header logo={false} dark={false} headline={"Maps"} params={true} />
      <div className={"map"}>
        <img src={Map} alt="map" />
      </div>
    </div>
  );
};

export default Maps;
