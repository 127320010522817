const NotesArray = {
  notes: [
     {
      id: "0",
      title: "Audition",
      time: "1pm",
      date: "3/01",
      excerpt: "Audition tomorrow...",
      note: "Audition tomorrow. Don't be late!",
      scene: 0
    },
    {
      id: "1",
      title: "Tarot",
      time: "1pm",
      date: "3/01",
      excerpt: "See a tarot reader...",
      note: "See a tarot reader about the card I found. Main St. and Grand.",
      scene: 30
    },
    {
      id: "2",
      title: "Library",
      time: "10pm",
      date: "2/12",
      excerpt: "Librarian says to check...",
      note:
        "Librarian says to check outside of town for answers. She didn't have any answers for me. Maybe the book is harder to find than I thought it would be.",
      scene: 60
    },
    {
      id: "3",
      title: "Book/Library",
      time: "8am",
      date: "1/09",
      excerpt: "Look for books...",
      note:
        "Look for books about doppelgangers. Questions might have some Answers too...",
      scene: 55
    },
    {
      id: "4",
      title: "Magic Show",
      time: "10am",
      date: "5/30",
      excerpt: "Magic Show...",
      note: "Magic Show June 6, 8pm - Looks interesting. Maybe invite Devin?",
      scene: 5
    }
  ],
  all: function() {
    return this.notes;
  },
  recent: function(currentScene) {
    const isMatch = element => element.scene - currentScene <= 1;
    return this.notes.filter(isMatch);
  },
  get: function(id) {
    const isMatch = element => element.id.toString() === id.toString();
    return this.notes.find(isMatch);
  }
};

export default NotesArray;
