const Scene2 = [
  {
    id: 8,
    headline:
      "YOUR ORDINARY WORLD IS FAILING YOU.\n\nYOU HAVE TO CHANGE.\n\nOBSTACLE.",
    link_text: "Continue",
    content:
      "You're not surprised but when you get turned down for the role, you get depressed and angry. You hate LA. You hate Hollywood. \n\nAfraid of doctors and going back on SSRIs, you self-medicate with drugs—weed and alcohol mostly. They help you tolerate going to your menial day job, and open calls, and getting rejected, and hanging out with your richer, more successful friends, whom you resent and feel superior to. \n\nYou fantasize about them failing in embarrassing ways.",
    links: [9],
  },
  {
    id: 9,
    headline: "",
    link_text: "Continue",
    content:
      "Hanging out, you complain that the Industry isn't more of a meritocracy. \"Why isn't it enough to be talented and cool?\" Your friends can't relate to the question. They never listen to you, you think.",
    links: [10],
  },
  {
    id: 10,
    headline: "",
    link_text: "Continue",
    content:
      '[link:spacebook/user/15?thisScene=10 text:KARLY], a model, has found her "twin stranger" on [link:spacebook?thisScene=10 text:Spacebook] and is messaging with her. "She lives in Miami."\n\n"That\'s a terrible idea," says [link:spacebook/user/preppieForLife?thisScene=10 text:JOSH], looking up from his legal pad. "She could be crazy."\n\n"Maybe she really is your twin," says [link:spacebook/user/devin-646?thisScene=10 text:DEVIN], another model, "and you were separated at birth for some reason."\n\n"They say everybody has a double," says [link:spacebook/user/tyGuyTuber?thisScene=10 text:TYLER], a blond actor.',
    links: [11],
  },
  {
    id: 11,
    headline: "",
    link_text: "Continue",
    content:
      "JOSH explains the [link:questions/search/doppelganger?thisScene=11 text:doppelganger] mythology: that it is a bad omen, and that encountering one is a harbinger of misfortune and death. \n\n\"It might be true, but not for supernatural reasons,\" he says, toking. \n\n\"There are mental illnesses that make people think they see doubles of themselves. If your depression or whatever progresses that far, you're probably going to die doing something crazy or by suicide. Regardless, I don't think I'd message with my double. Then again, he'd be a lot more interesting—not to mention better looking—than any of you.\"",
    links: [12],
  },
  {
    id: 12,
    headline: "",
    link_text: "Continue",
    content:
      "All of your friends are trendy, shallow narcissists. JOSH is the least offensive. \n\nThey are always looking at their phones and gossiping and dropping names and numbers—the prices of cars, clothes, salaries and film budgets and grosses. They are ahead of you in every way. \n\nYou're envious of them even though you kind of hate them.",
    links: [13],
  },
  {
    id: 13,
    headline: "",
    link_text: "Continue",
    content:
      "Because of your envy and depression, you withdraw and miss parties and thus opportunities to network. You miss the celebrity-studded dedication of the new OWT center Downtown.",
    links: [14],
  },
  {
    id: 14,
    headline: "",
    link_text: "Continue",
    content:
      "You have a frightening encounter with a homeless person in a parking lot. He mentions a dead body rolled in sleeping bag.",
    links: [15],
  },
  {
    id: 15,
    headline: "",
    link_text: "Continue",
    content:
      "You experience some slight depersonalization - derealization - dissociation. Sometimes it troubles you — you feel like you don't know who you are or what your goals are. You feel like a stranger to yourself.  \n\nSometimes it is a relief, an intentional coping mechanism you developed as a kid. You space out and daydream, putting yourself in imagined or remembered scenes from TV shows and The Wizard of Oz. \n\nOne minute your friends are shallow morons who do not know or care about you, and you don't really know or care about them either, and the next minute you admire them and seek their approval. Now with your uncle dead, they're all you have. \n\nYou're not going anywhere not on their coat tails.",
    links: [16],
  },
  {
    id: 16,
    headline: "",
    link_text: "Continue",
    content:
      "You're facing trying to get your old job back as a Find-A-Death tour guide—a depressing prospect.",
    links: [17],
  },
  {
    id: 17,
    headline: "You feel the pull of your past",
    link_text: "Continue",
    content:
      "Depression and/or an old friend contacts you via [link:spacebook?thisScene=17 text:Spacebook] who you have been avoiding for some reason. OR – Maybe you get a message from your aunt that your uncle died of a heart attack and has already been cremated.",
    links: [18],
  },
  {
    id: 18,
    headline: "Non-sequitur, POV shift",
    link_text: "Continue",
    content:
      "A man and a young woman are driving across the desert in a black car. He is serious and chain smokes; she is cheerful. He ignores her chatter and questions. They pull through the gate of a compound surrounded by armed guards.",
    links: [19],
  },
];

export default Scene2;
