import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as storyActions from "../../redux/actions";
import "./splash.css";

const Splash = () => {
  const dispatch = useDispatch();
  const scene = useSelector((state) => state.storyReducer.scene);

  return (
    <div
      className="screen--home has-image"
      style={{ backgroundImage: 'url("../images/entryPage.v3.png")' }}
    >
      <div className="App">
        <h1>Doppelganger</h1>
        {scene !== 0 ? (
          <div>
            <Link
              className={"button button--home"}
              onClick={() => dispatch(storyActions.freshStart())}
              to={`/scene/0`}
            >
              Start
            </Link>
            <Link className={"button button--home"} to={`/scene/${scene}`}>
              Continue
            </Link>
          </div>
        ) : (
          <Link
            className={"button button--home"}
            onClick={() => dispatch(storyActions.freshStart())}
            to={`/scene/0`}
          >
            Start
          </Link>
        )}
      </div>
    </div>
  );
};

export default Splash;
