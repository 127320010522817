import React, { Component } from "react";
import { Header, List } from "../components";
import Text from "./Text";

import TextsArray from "../apps-data/texts";

import "./index.css";

const listOne = TextsArray.all();

class Texts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeID: 0,
      text: TextsArray.get("Keith"),
    };

    this.switchText = this.switchText.bind(this);
  }

  switchText(id) {
    this.setState({ activeID: id, text: TextsArray.get(id) });
  }

  render() {
    const noteClass = this.state.activeID !== 0 ? "visible" : "hidden";
    const listClass = this.state.activeID === 0 ? "visible" : "hidden";

    return (
      <div className="app--texts">
        <Header
          logo={false}
          dark={false}
          headline={"Texts"}
          params={this.props.match.params}
        />
        <div className="app--body row">
          <div className={`app--sidebar col-100 ${listClass}`}>
            {this.state.activeID === 0 && (
              <List list={listOne} switchNote={this.switchText} />
            )}
          </div>
          <div className={`app--text col-100 ${noteClass}`}>
            {this.state.activeID !== 0 && (
              <Text
                id={this.state.activeID}
                text={this.state.text}
                showList={() => this.switchText(0)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Texts;
