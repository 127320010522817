import React from "react";
import { Icon } from "../components";

const Text = (props) => {
  return (
    <div className="container">
      <div onClick={props.showList}>
        <Icon name="carat-left" height={16} width={16} />
      </div>
      <ul className="list--messages">
        {props.text.messages.map((message, i) => {
          return (
            <li key={i}>
              {i % 2 === 0 && <p>{props.id}</p>}
              <div>{message.text}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Text;
