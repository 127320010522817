const Scene3 = [
  {
    id: 19,
    headline: "INCITING INCIDENT",
    link_text: "Continue",
    content:
      "At a Silver Lake coffee shop, the barista thinks you have already been in once this morning. You go with it, open your laptop and scan open casting calls.\n\nThe actress [link:spacebook/user/4?thisScene=19 text:CARRIE SWANSON] mistakes you for someone else. She sees over your shoulder that you are looking for work and gives you the name and phone number of LESLEY CRANE, the assistant director of a horror movie she's in that's in pre-production called The Murmur Ritual. CARRIE suggests there may be something for you.\n\n\"It's non-union, strictly from poverty, but you'll get to meet a lot of people,\" she says.\n\nYou thank her, suspicious of her generosity because you have heard she is a diva on set, but mostly you are so grateful you don't think about it.",
    links: [0],
  },
];

export default Scene3;
