import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { returnToScene } from "../../../../redux/actions";

const ExitApp = (props) => {
  const dispatch = useDispatch();
  const currentScene = useSelector(
    (storyReducer) => storyReducer.storyReducer.scene
  );
  const shouldReturnToScene = useSelector(
    (storyReducer) => storyReducer.storyReducer.returnToScene
  );

  if (!props.params) {
    return null;
  }

  let exitLink = (
    <Link className={"button button--close"} to="/phone">
      &times;
    </Link>
  );

  if (shouldReturnToScene) {
    exitLink = (
      <Link
        className={"button button--close"}
        onClick={() => dispatch(returnToScene(false))}
        to={`/scene/${currentScene}`}
      >
        &times;
      </Link>
    );
  }

  if (props.params !== undefined && typeof props.params === "object") {
    if ("nextScene" in props.params) {
      exitLink = (
        <Link
          className={"button button--close"}
          onClick={() => dispatch(returnToScene(false))}
          to={`/scene/${props.params.nextScene}`}
        >
          &times;
        </Link>
      );
    }
    if ("thisScene" in props.params) {
      exitLink = (
        <Link
          className={"button button--close"}
          onClick={() => dispatch(returnToScene(false))}
          to={`/scene/${props.params.thisScene}`}
        >
          &times;
        </Link>
      );
    }
  }

  return exitLink;
};

export default ExitApp;
