import React from "react";

const Nose = ({ colorScheme: { type, nose } }) => {
  if (type === "human") {
    return (
      <circle
        id="Nose"
        fill={nose}
        fillRule="nonzero"
        cx="191.5"
        cy="168.5"
        r="11.5"
      ></circle>
    );
  }
  return <circle id="Nose" fill={nose} cx="189.5" cy="177.5" r="11.5"></circle>;
};

export default Nose;
