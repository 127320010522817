const Scene1 = [
  {
    id: 2,
    headline: "",
    link_text: "Fade to black",
    content:
      'You finish your reading. You were nervous and it didn’t go well. The casting director and AD are rude and impassive.\n\n"We\'ll be in touch."',
    links: [3],
  },
  {
    id: 3,
    link_text: "Continue",
    content:
      'Teary-eyed, you call your uncle and tell him the audition went badly, that what casting directors want is generic. He encourages you, telling you that you are a unique individual. \n\nYou check your [link:infinity/3 text:horoscope] hoping to get some insight. \n\nYou text a friend: "Nailed the audition. Really optimistic."\n\nYou vape in the car and stop at a bar to have a drink. You check your phone constantly to appear less alone.',
    links: [4],
  },
  {
    id: 4,
    link_text: "Continue",
    content:
      "Something random and violent happens — a fight between a surly drunk and the bouncer, or someone walks into the street and is hit by a car. It takes you a minute to realize what's really happening, that you're not daydreaming.",
    links: [5],
  },
  {
    id: 5,
    link_text: "Continue",
    content:
      "After dark, you assure the bartender you're good to drive and leave. The radio says there's a wreck on the 10",
    links: [6, 7],
  },
  {
    id: 6,
    headline: "10",
    link_text: "Take the 10 anyway",
    content:
      'You take the 10. You sing along to "Kyrie" by Mr. Mister on KT102, tapping your ring on the steering wheel. Traffic is slow but you see a cute guy in the car beside you and end up talking. You give him your number.',
    links: [8],
  },
  {
    id: 7,
    headline: "405",
    link_text: "Stay on the 405",
    content:
      'You stay on the 405 to the Valley. You sing along to "Kyrie" by Mr. Mister on KT102, tapping your ring on the steering wheel. You\'re home before you know it.',
    links: [8],
  },
];

export default Scene1;
