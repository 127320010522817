import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as storyActions from "./redux/actions";

import "./App.css";

import Routes from "./Routes";

const App = () => <Routes />;

function mapStateToProps(state) {
  return {
    story: state.storyReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeScene: storyActions.changeScene
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
