import React from "react";
import { Header } from "../";

import "./index.css";

const AppWrapper = ({
  appType,
  bodyClass = "",
  children,
  dark = true,
  headline,
  logo,
  params = null,
}) => (
  <div className={`app-wrapper ${appType}`}>
    <Header logo={logo} dark={dark} headline={headline} params={params} />
    <div className={bodyClass}>{children}</div>
  </div>
);

export default AppWrapper;
