import React, { Component } from "react";

import { Link } from "react-router-dom";

import phoneIcon from "./icons/AppIcon-PhoneMini.png";
// import notesIcon from "./icons/AppIcon-NotesMini.png";
// import cameraIcon from "./icons/AppIcon-CameraMini.png";

class Sidebar extends Component {
  render() {
    return (
      <div className="sidebar">
        <Link className="button--apps" to={"/phone"}>
          <img src={phoneIcon} alt="Phone" />
        </Link>
        {/* <Link
          className="button--apps button--notes"
          to={`/notes/${this.props.link}`}
        >
          <img src={notesIcon} alt="Phone" />
        </Link>
        <Link
          className="button--apps button--camera"
          to={`/photos/${this.props.link}`}
        >
          <img src={cameraIcon} alt="Phone" />
        </Link> */}
      </div>
    );
  }
}

export default Sidebar;
