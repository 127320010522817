import React from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import reducers from './app/redux/reducers';

import * as serviceWorker from './serviceWorker';

import App from './app/App';

import './index.css';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

function configureStore() {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = configureStore();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <App />
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
