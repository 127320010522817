import * as types from "../actions/types";

const initialState = {
  notes: [0, 1],
  previousScenes: [0],
  question: "doppelganger",
  refreshScene: false,
  returnToScene: false,
  scene: 0,
  texts: [0, 1],
};

export default function storyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_PREVIOUS_SCENE: {
      let prev = [action.scene];
      if (state && state.previousScenes) {
        prev = [...state.previousScenes, action.scene];
      }
      return {
        ...state,
        previousScenes: prev,
      };
    }

    case types.FRESH_START:
      return {
        state: initialState,
      };

    case types.NOTES:
      if (state.notes.indexOf(action.notes) > -1) {
        return state;
      }

      return {
        ...state,
        notes: [...state.notes, action.notes],
      };

    case types.QUESTION:
      return {
        ...state,
        question: action.question,
      };

    case types.REFRESH:
      return {
        ...state,
        refreshScene: action.shouldRefresh,
      };

    case types.REMOVE_PREVIOUS_SCENE:
      return {
        ...state,
        previousScenes: [
          ...state.previousScenes.slice(0, state.previousScenes.length - 1),
        ],
      };

    case types.RETURN_TO_SCENE:
      return {
        ...state,
        returnToScene: action.returnToScene,
      };

    case types.SCENE:
      return {
        ...state,
        scene: action.scene,
      };

    case types.TEXTS:
      if (state.texts.indexOf(action.texts) > -1) {
        return state;
      }

      return {
        ...state,
        texts: [...state.texts, action.texts],
      };

    default:
      return state;
  }
}
