import React from "react";
import { Icon } from "../components";
const Note = ({ note: { title, note }, showList }) => {
  return (
    <div className="container">
      <div onClick={showList}>
        <span>
          <Icon name="carat-left" height={16} width={16} />
        </span>{" "}
        <h1>{title}</h1>
      </div>
      <p>{note}</p>
    </div>
  );
};

export default Note;
