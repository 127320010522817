import React from "react";
import { Link } from "react-router-dom";
import { UserImage } from "./index";
import CharacterArray from "../../apps-data/characters";

const PostsList = ({ posts }) => {
  return (
    <div className="cards">
      {posts.map((post, i) => {
        const character = CharacterArray.get(post.user);
        return (
          character && (
            <div className="card" key={i}>
              <div className="card__signature">
                <Link to={`/spacebook/user/${post.user}`}>
                  <UserImage data={character} />
                  <span className="card__name">{character.name}</span>
                </Link>
              </div>
              <div className="card__content">
                <p>{post.message}</p>
                <p className="hashtags">{post.hashtags}</p>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default PostsList;
