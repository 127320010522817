import React, { Component } from "react";
import { Link } from "react-router-dom";
import { shuffle } from "../../../../utils";

const API =
  "https://en.wikipedia.org/w/api.php?origin=*&action=opensearch&limit=3&search=";

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wiki: null
    };
  }

  componentDidMount() {
    let __this = this;
    fetch(API + this.props.term)
      .then(function(resp) {
        return resp.json();
      })
      .then(function(data) {
        __this.setState({ wiki: data });
      });
  }
  returnHTML = content => {
    return { __html: content };
  };
  renderList(listOne) {
    let wikiThree = this.state.wiki.slice(1, 4);
    let searchResults = listOne.map(function(result, i) {
      return (
        <li className={"item--result"} key={result.title + i}>
          <span className={"title"}>{result.title}</span>
          <span className={"text"}>{result.text}</span>
          <Link className={"url"} to={result.webview}>
            Learn More
          </Link>
        </li>
      );
    });

    let wikiresults = "";

    wikiresults = wikiThree.map(function(result, i) {
      let link = wikiThree[2][i]
        .replace("https://en.wikipedia.org/wiki/", "")
        .toLowerCase();
      return (
        <li className={"item--result"} key={result[0][0] + i}>
          <span className={"title"}>{wikiThree[0][i]}</span>
          <span className={"text"}>{wikiThree[1][i]}</span>
          <Link className={"url"} to={`/questions/${link}`}>
            Learn More
          </Link>
        </li>
      );
    });
    return (
      <ul className="list--results">
        {searchResults}
        {wikiresults}
      </ul>
    );
  }

  render() {
    let listOne = this.props.searchData;
    if (listOne !== undefined) {
      shuffle(listOne.results);

      if (this.state.wiki !== null) {
        return <div>{this.renderList(listOne.results)}</div>;
      } else {
        return <div>Loading Results</div>;
      }
    } else {
      return (
        <ul className="list--results">
          <li className={"item--result"}>
            <span className={"title"}>No Results</span>
            <span className={"text"}>Not all Questions have Answers.</span>
            <span className={"disclaimer"}>
              Try using one word or a simple phrase.
            </span>
          </li>
        </ul>
      );
    }
  }
}

export default Results;
