import * as types from "./types";

export const addNote = (notes) => {
  return {
    notes,
    type: types.NOTES,
  };
};

export const addPreviousScene = (scene) => {
  return {
    scene,
    type: types.ADD_PREVIOUS_SCENE,
  };
};

export const addText = (texts) => {
  return {
    texts,
    type: types.TEXTS,
  };
};

export const changeScene = (scene) => {
  return {
    type: types.SCENE,
    scene,
  };
};

export const freshStart = () => {
  return {
    type: types.FRESH_START,
  };
};

export const Login = (user) => {
  return {
    type: types.LOGIN,
    user,
  };
};

export const refreshScene = (shouldRefresh) => {
  return {
    shouldRefresh,
    type: types.REFRESH,
  };
};

export const removePreviousScene = () => {
  return {
    type: types.REMOVE_PREVIOUS_SCENE,
  };
};

export const returnToScene = (returnToScene) => {
  return {
    returnToScene,
    type: types.RETURN_TO_SCENE,
  };
};

export const setQuestion = (question) => {
  return {
    type: types.QUESTION,
    question,
  };
};
