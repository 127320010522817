import React from "react";

const Eyes = ({ colorScheme: { type, eyes } }) => {
  if (type === "human") {
    return (
      <g>
        <g
          id="Right-Eye"
          transform="translate(233.000000, 142.500000) scale(-1, 1) translate(-233.000000, -142.500000) translate(217.000000, 132.000000)"
        >
          <g id="White" fill="#FFFFFF" fillRule="nonzero">
            <path
              d="M15,21 C23.836556,21 32,17.836556 32,9 C32,4.0019858 23.2647934,0.0924223821 16,0.0924223821 C8.7352066,0.0924223821 0,5.01051748 0,9 C0,12.9894825 6.163444,21 15,21 Z"
              id="path-7"
            ></path>
          </g>
          <g id="Iris">
            <mask id="mask-4" fill="white">
              <use href="#path-3"></use>
            </mask>
            <g id="path-7"></g>
            <circle
              id="Oval"
              fill={eyes}
              fillRule="nonzero"
              mask="url(#mask-4)"
              cx="16"
              cy="9"
              r="10"
            ></circle>
          </g>
          <g id="Pupil">
            <mask id="mask-6" fill="white">
              <use href="#path-5"></use>
            </mask>
            <g id="path-7"></g>
            <circle
              id="Oval"
              fill="#000000"
              fillRule="nonzero"
              mask="url(#mask-6)"
              cx="16"
              cy="9"
              r="5"
            ></circle>
          </g>
        </g>
        <g id="Left-Eye" transform="translate(134.000000, 132.000000)">
          <g id="White" fill="#FFFFFF" fillRule="nonzero">
            <path
              d="M15,21 C23.836556,21 32,17.836556 32,9 C32,4.0019858 23.2647934,0.0924223821 16,0.0924223821 C8.7352066,0.0924223821 0,5.01051748 0,9 C0,12.9894825 6.163444,21 15,21 Z"
              id="path-5"
            ></path>
          </g>
          <g id="Iris">
            <mask id="mask-8" fill="white">
              <use href="#path-7"></use>
            </mask>
            <g id="path-5"></g>
            <circle
              id="Oval"
              fill={eyes}
              fillRule="nonzero"
              mask="url(#mask-8)"
              cx="16"
              cy="9"
              r="10"
            ></circle>
          </g>
          <g id="Pupil">
            <mask id="mask-10" fill="white">
              <use href="#path-9"></use>
            </mask>
            <g id="path-5"></g>
            <circle
              id="Oval"
              fill="#000000"
              fillRule="nonzero"
              mask="url(#mask-10)"
              cx="16"
              cy="9"
              r="5"
            ></circle>
          </g>
        </g>
      </g>
    );
  }
  return (
    <g id="Eyes" transform="translate(131.000000, 134.000000)">
      <circle id="Oval-Copy-4" fill="#FFFFFF" cx="16" cy="16" r="16"></circle>
      <circle id="Oval-Copy-5" fill="#FFFFFF" cx="102" cy="16" r="16"></circle>
      <circle id="Oval" fill="#50E3C2" cx="16" cy="18" r="10"></circle>
      <circle id="Oval-Copy-6" fill="#50E3C2" cx="102" cy="18" r="10"></circle>
      <circle id="Oval" fill="#000000" cx="16" cy="18" r="5"></circle>
      <circle id="Oval-Copy-7" fill="#000000" cx="102" cy="18" r="5"></circle>
    </g>
  );
};

export default Eyes;
