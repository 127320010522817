import React from "react";

import topbarDark from "./topbar.dark.png";
import topbarLight from "./topbar.white.png";

const TopBar = (props) => {
  if (props.dark) {
    return <img className={"topbar"} src={topbarDark} alt="time" />;
  } else {
    return <img className={"topbar"} src={topbarLight} alt="time" />;
  }
};

export default TopBar;
