import React from "react";
import { ExitApp, Headline, Logo, TopBar } from "../index";

import "./header.css";

const Header = ({ classes, dark, headline, logo, params }) => {
  const headerClasses = dark ? classes + " dark" : classes;
  return (
    <header className={headerClasses}>
      <div className="container">
        <Logo logo={logo} />
        <Headline headline={headline} />
        <TopBar dark={dark} />
        <ExitApp params={params} />
      </div>
    </header>
  );
};

export default Header;
