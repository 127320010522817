// A simple data API that will be used to get the data for our components.
const TextsArray = {
  texts: [
    {
      id: "Devin",
      date: "06/15",
      scene: 0,
      time: "10pm",
      messages: [
        { text: "Hey! What time are you heading this way?" },
        { text: "ASAP!!" },
        { text: "Awesome 😄" },
        { text: "👍 👍 👍 " }
      ]
    },
    {
      id: "Carrie",
      date: "05/10",
      scene: 10,
      time: "8am",
      messages: [
        { text: "Hey! Can you drive today?" },
        { text: "Oh sure, no problem" },
        { text: "I can't find my car keys! 😛" },
        { text: "Hahaha! np" }
      ]
    },
    {
      id: "Cary",
      date: "05/09",
      scene: 20,
      time: "9am",
      messages: [
        { text: "Why did you ignore me last night?" },
        { text: "I was at home all night. What are you talking about?" },
        {
          text:
            "I saw you at Devin's party. You were walking with Carrie and you both totally ignored me."
        },
        {
          text:
            "I haven't seen Carrie in a week. You must've been seeing things!"
        },
        {
          text:
            "ok"
        }
      ]
    }
  ],
  all: function() {
    return this.texts;
  },
  recent: function(currentScene) {
    const isMatch = element => element.scene - currentScene <= 1;
    return this.texts.filter(isMatch);
  },
  get: function(id) {
    const isMatch = element => element.id.toString() === id.toString();
    return this.texts.find(isMatch);
  }
};

export default TextsArray;
