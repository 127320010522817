import React, { Component } from "react";
import Note from "./Note";
import { Header, List } from "../components";

import NotesArray from "../apps-data/notes";

import "./index.css";

const listOne = NotesArray.all();

class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeID: 0,
      note: NotesArray.get(0),
    };

    this.switchNote = this.switchNote.bind(this);
  }

  componentDidMount() {
    this.setState({ activeID: 0 });
  }

  switchNote(id) {
    this.setState({ activeID: id, note: NotesArray.get(id) });
  }

  render() {
    const noteClass = this.state.activeID !== 0 ? "visible" : "hidden";
    const listClass = this.state.activeID === 0 ? "visible" : "hidden";

    return (
      <div className="app--notes">
        <Header
          logo={false}
          dark={false}
          headline={"Notes"}
          params={this.props.match.params}
        />
        <div className="app--body row">
          <div className={`app--sidebar col-100 ${listClass}`}>
            {this.state.activeID === 0 && (
              <List color="pink" list={listOne} switchNote={this.switchNote} />
            )}
          </div>
          <div className={`app--note col-100 ${noteClass}`}>
            {this.state.activeID !== 0 && (
              <Note
                note={this.state.note}
                showList={() => this.switchNote(0)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Notes;
