import React from "react";

const Mouth = ({ colorScheme: { type, mouth } }) => {
  if (type === "human") {
    return (
      <path
        d="M160,196 L226,196 C220,213.708997 209.166667,222.563495 193.5,222.563495 C177.833333,222.563495 166.666667,213.708997 160,196 Z"
        id="Mouth"
        fill={mouth}
        fillRule="nonzero"
      ></path>
    );
  }
  return (
    <g transform="translate(146.000000, 157.000000)">
      <circle fill={mouth} cx="44" cy="44" r="44"></circle>
      <path
        d="M11,44 L77,44 C73,65.8079406 62,76.7119108 44,76.7119108 C26,76.7119108 15,65.8079406 11,44 Z"
        fill="#FFFFFF"
      ></path>
    </g>
  );
};

export default Mouth;
