import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as storyActions from "../../redux/actions";

import { Header } from "../apps/components";

import "./screen.css";

import allSeeing from "./icons/AppIcon-Eye.png";
import mapIcon from "./icons/AppIcon-Map.png";
import calIcon from "./icons/AppIcon-Calendar.png";
import photoIcon from "./icons/AppIcon-Camera.png";

import questionsIcon from "./icons/AppIcon-Internet.png";
import foodIcon from "./icons/AppIcon-Eat.png";
import videosIcon from "./icons/AppIcon-Video.png";
import backIcon from "./icons/AppIcon-BACK.v2.png";

import notesIcon from "./icons/AppIcon-Notes.png";
import infinityIcon from "./icons/AppIcon-Infinity.png";
import socialIcon from "./icons/AppIcon-Social.png";
import qliqkIcon from "./icons/AppIcon-Qliqk.png";

import textIcon from "./icons/AppIcon-Text.png";
import phoneIcon from "./icons/AppIcon-Phone.png";
import mailIcon from "./icons/AppIcon-Mail.png";

class Screen extends Component {
  render() {
    let bodyclass = "screen--apps has-image";

    return (
      <div
        className={bodyclass}
        style={{ backgroundImage: 'url("../images/AppPhoneBackground.png")' }}
      >
        <Header logo={false} dark={false} headline="" params={false} />
        <div className="App screen">
          <div className={"apps"}>
            <Link className={"button--apps"} to={"/future"}>
              <img src={allSeeing} alt="All Seeing" />
            </Link>
            <Link className={"button--apps"} to={"/map"}>
              <img src={mapIcon} alt="Map App" />
            </Link>
            <Link className={"button--apps"} to={"/calendar"}>
              <img src={calIcon} alt="Calendar" />
            </Link>

            <Link className={"button--apps"} to={"/photos"}>
              <img src={photoIcon} alt="Photos" />
            </Link>
            <Link className={"button--apps"} to={"/food"}>
              <img src={foodIcon} alt="Food" />
            </Link>
            <Link className={"button--apps"} to={"/infinity"}>
              <img src={infinityIcon} alt="Infinity" />
            </Link>

            <Link className={"button--apps"} to={"/qliqk"}>
              <img src={qliqkIcon} alt="Qliqk" />
            </Link>
            <Link className={"button--apps"} to={"/videos"}>
              <img src={videosIcon} alt="Videos" />
            </Link>
            <Link className={"button--apps"} to={"/spacebook"}>
              <img src={socialIcon} alt="Spacebook" />
            </Link>

            <Link className={"button--apps"} to={"/notes"}>
              <img src={notesIcon} alt="Notes" />
            </Link>
            <Link className={"button--apps"} to={"/questions/search"}>
              <img src={questionsIcon} alt="Questions" />
            </Link>
            <Link
              className={"button--apps"}
              to={`/scene/${this.props.story.scene}`}
            >
              <img src={backIcon} alt="Back to Story" />
            </Link>
          </div>
          <div className={"sidebar"}>
            <Link className={"button--apps"} to={"/texts"}>
              <img src={textIcon} alt="Texts" />
            </Link>
            <Link className={"button--apps"} to={"/phone"}>
              <img src={phoneIcon} alt="Phone" />
            </Link>
            <Link className={"button--apps"} to={"/phone"}>
              <img src={mailIcon} alt="Email" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    story: state.storyReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeScene: storyActions.changeScene,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Screen));
