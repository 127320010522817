import React from "react";
import { Link } from "react-router-dom";

import { Header } from "../components";
import { SpacebookHeader, UserImage } from "./elements";

import CharacterArray from "../apps-data/characters";

import "./index.css";

const SpacebookFriends = () => {
  const characters = CharacterArray.friends();

  return (
    <div className="spacebook">
      <Header classes={null} dark={false} headline={""} params={true} />
      <SpacebookHeader />
      <div>
        <section className="feed">
          <div className="cards">
            {characters.map((character, i) => {
              return (
                <div className="card" key={i}>
                  <div className="card__signature">
                    <Link to={`/spacebook/user/${character.id}`}>
                      <UserImage data={character} />
                      <span className="card__name">{character.name}</span>
                    </Link>
                    <p>{character.tagline}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
};
export default SpacebookFriends;
