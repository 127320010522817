import React from "react";

const Base = ({ colorScheme: { type, circleBackground, shirt, skin } }) => {
  if (type === "human") {
    return (
      <g id="Background">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Background-Mask" fill={circleBackground} href="#path-1"></use>
        <circle
          id="Body"
          fill={shirt}
          mask="url(#mask-2)"
          cx="189.5"
          cy="399.5"
          r="175.5"
        ></circle>
        <circle id="Head" fill={skin} cx="189.5" cy="148.5" r="101.5"></circle>
      </g>
    );
  }
  return (
    <g id="Animal-Base">
      <g id="Oval-Copy">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Mask" fill={circleBackground} href="#path-1"></use>
        <circle
          id="Oval"
          fill="#FFB33A"
          mask="url(#mask-2)"
          cx="189.5"
          cy="399.5"
          r="175.5"
        ></circle>
      </g>
      <circle
        id="Oval-Copy-2"
        fill="#FFB33A"
        cx="189.5"
        cy="165.5"
        r="101.5"
      ></circle>
      <circle id="Oval" fill="#FFB33A" cx="107.5" cy="104.5" r="29.5"></circle>
      <circle
        id="Oval-Copy-3"
        fill="#FFB33A"
        cx="271.5"
        cy="104.5"
        r="29.5"
      ></circle>
    </g>
  );
};

export default Base;
