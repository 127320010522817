import React from "react";

const Icon = ({ height, name, width }) => {
  switch (name) {
    case "carat-right":
      return (
        <svg viewBox="0 0 40 65" height={height} width={width}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
              fill="#000000"
              transform="translate(19.970642, 32.500000) rotate(-180.000000) translate(-19.970642, -32.500000) "
              points="0 32.3986554 32.3986554 0 38.9546275 6.55597214 13.248333 32.2622666 39.9412832 57.9685611 32.3986554 65"
            ></polygon>
          </g>
        </svg>
      );
    case "carat-left":
      return (
        <svg viewBox="0 0 40 65" height={height} width={width}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
              fill="#000000"
              points="0 32.3986554 32.3986554 0 38.9546275 6.55597214 13.248333 32.2622666 39.9412832 57.9685611 32.3986554 65"
            ></polygon>
          </g>
        </svg>
      );
    default:
      return null;
  }
};

export default Icon;
