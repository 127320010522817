import React from "react";
import AppWrapper from "../components/wrapper";
import "./index.css";

const Food = () => (
  <AppWrapper
    appType="app--food"
    bodyClass="app--body"
    dark={false}
    headline={"Food"}
    logo={false}
    params={true}
  >
    <div className={"app--food app--body"} />
  </AppWrapper>
);

export default Food;
