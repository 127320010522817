import React from "react";

const Logo = ({ logo }) => {
  if (logo) {
    return <img className={"logo"} src={logo} alt="Logo" />;
  } else {
    return null;
  }
};

export default Logo;
