import React from "react";
import { Header } from "../components";

import "./index.css";

const Videos = (props) => {
  return (
    <div className="app--videos">
      <Header logo={false} dark={false} headline={"Videos"} params={true} />
      <div className={"videos"} />
    </div>
  );
};

export default Videos;
