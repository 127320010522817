import React, { Component } from "react";
import { Link } from "react-router-dom";

import articleArray from "../../apps-data/articles";

import "./article.css";

const API =
  "https://en.wikipedia.org/w/api.php?origin=*&format=json&action=query&prop=extracts&exintro=&titles=";

const StoryArticle = (props) => {
  if (props.articleContent) {
    return (
      <div
        className={"component--text-p"}
        dangerouslySetInnerHTML={props.articleContent}
      />
    );
  } else {
    return null;
  }
};

class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wiki: null,
    };
  }

  componentDidMount() {
    let __this = this;
    fetch(API + this.props.article)
      .then(function (resp) {
        return resp.json();
      })
      .then(function (data) {
        const objKey = Object.keys(data.query.pages)[0];
        let wikiContent;
        if (
          data.query.pages[objKey].extract !== "" &&
          data.query.pages[objKey].extract !== undefined
        ) {
          wikiContent = data.query.pages[objKey].extract;
          __this.setState({ wiki: wikiContent });
        }
      });
  }
  returnHTML = (content) => {
    return { __html: content };
  };

  render() {
    let articleData = articleArray.get(this.props.article);
    let articleContent =
      articleData !== undefined ? this.returnHTML(articleData.content) : null;
    if (this.state.wiki !== null) {
      return (
        <div className="app--article">
          <div className={"app--body article"}>
            <div className="container">
              <Link
                className={"button--back"}
                to={`/questions/search/${this.props.article}`}
              >
                &lt; Ask Again
              </Link>
              <div className={"article--body"}>
                <h1>{this.props.article.replace(/_/g, " ")}</h1>
                <StoryArticle articleContent={articleContent} />
                <div className={"component--wiki"}>
                  <div
                    className={"component--text-p"}
                    dangerouslySetInnerHTML={this.returnHTML(this.state.wiki)}
                  />
                  <small>From Wikipedia</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>HI</div>;
    }
  }
}

export default Article;
