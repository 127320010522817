import React from "react";
import { Link } from "react-router-dom";

import News from "../images/news.v2@2x.png";
import Friends from "../images/friends.v2@2x.png";

const Sidebar = () => {
  return (
    <section className="sidebar">
      <div className="container">
        <nav>
          <li>
            <img src={News} alt="news" />
            <Link to={"/spacebook/search"}>Search</Link>
          </li>
          <li>
            <img src={Friends} className="icon__friends" alt="friends" />
            <Link to={"/spacebook/friends"}>Friends</Link>
          </li>
        </nav>
      </div>
    </section>
  );
};

export default Sidebar;
