import { combineReducers } from "redux";

// Reducers
import storyReducer from "./story-reducers";

// Combine Reducers
var reducers = combineReducers({
  storyReducer: storyReducer
});

export default reducers;
