const Horoscopes = {
  predictions: [
    {
      scene: 0,
      message:
        "There comes a point when a dream becomes reality and reality becomes a dream."
    },
    {
      scene: 2,
      message:
        "Are you working towards taking a big step in a new direction? Try to get someone close to help out."
    },
    {
      scene: 4,
      message: "You're going to have to make a big decision today."
    },
    {
      scene: 5,
      message: "Two roads diverged in a yellow wood."
    },
    {
      scene: 6,
      message:
        "Do you ever take the road less traveled? Today is a great day to try it out!"
    },
    {
      scene: 7,
      message:
        "Do you always take the road less traveled? Today feels like a day to stay on the well traveled path."
    },
    {
      scene: 8,
      message: "Failure is always an option"
    },
    {
      scene: 15,
      message:
        "Don't question anything today. Accept everything that comes your way."
    },
    {
      scene: 18,
      message:
        "Don't question anything today. Accept everything that comes your way."
    },
    {
      scene: 60,
      message: "Today you meet the Dragon"
    }
  ],
  all: function() {
    return this.predictions;
  },
  latest: function(currentScene) {
    const isMatch = element => element.scene - currentScene <= 0;
    return this.predictions.filter(isMatch).reverse()[0];
  }
};

export default Horoscopes;
