import characterDB from "./character-db";

const Characters = {
  characters: [
    ...characterDB,
    {
      id: "0",
      name: "You",
      job: "",
      tagline: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      handle: "",
      colorScheme: {
        type: "human",
        circleBackground: "#FF5554",
        shirt: "#F45E83",
        skin: "#F9DEB7",
        hair: "#B83C12",
        nose: "#F8CA8E",
        eye: "#0D9044",
        hairType: "short",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "devin-646",
      name: "Devin",
      job: "Model",
      tagline: "",
      followerCount: 2687,
      followingCount: 18,
      friend: true,
      handle: "Devin",
      colorScheme: {
        type: "human",
        circleBackground: "#EB8EFE",
        shirt: "#65EECF",
        skin: "#F9DEB7",
        hair: "#FEF26B",
        nose: "#F8CA8E",
        eye: "#95B2FF",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "tyGuyTuber",
      name: "Tyler",
      job: "Influencer. Former YouTube sensation.",
      tagline: "",
      followerCount: 8642,
      followingCount: 12,
      friend: true,
      colorScheme: {
        type: "human",
        circleBackground: "#9861EC",
        shirt: "#1FAD8F",
        skin: "#FFE3AD",
        hair: "#180701",
        nose: "#EFCA84",
        eye: "#8E5B07",
        hairType: "short",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "preppieForLife",
      name: "Josh",
      job: "Writer. East Coast ex-pat",
      tagline: "",
      followerCount: 167,
      followingCount: 160,
      friend: true,
      colorScheme: {
        type: "human",
        circleBackground: "#8FEDFF",
        shirt: "#0D96AF",
        skin: "#FBD7C3",
        hair: "#FFAA51",
        nose: "#FAC1A2",
        eye: "#47BEFF",
        hairType: "short",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "4",
      name: "Carrie Swanson",
      tagline: "",
      job: "Actress",
      followerCount: 3678567,
      followingCount: 8,
      friend: true,
      handle: "TheCarrieSwanson",
      colorScheme: {
        type: "human",
        circleBackground: "#3157B8",
        shirt: "#B5FFEE",
        skin: "#F9DEB7",
        hair: "#121212",
        nose: "#F8CA8E",
        eye: "#729E6A",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "5",
      name: "Scott Raden",
      tagline: "",
      job: "Director",
      followerCount: 1296745,
      followingCount: 120,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#9861EC",
        shirt: "#F8E71C",
        skin: "#E8A569",
        hair: "#D96D27",
        nose: "#CB884D",
        eye: "#95B2FF",
        hairType: "curly",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "6",
      name: "Leslie Crane",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#50E3C2",
        shirt: "#1FAD8F",
        skin: "#E8A569",
        hair: "#4D3F3A",
        nose: "#CB884D",
        eye: "#4F38B1",
        hairType: "spiked",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "7",
      name: "Lawrence Mazar",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#3157B8",
        shirt: "#FEF26B",
        skin: "#652E10",
        hair: "#180701",
        nose: "#371807",
        eye: "#729E6A",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "8",
      name: "The Count",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#27D36F",
        shirt: "#A1EB94",
        skin: "#CB884D",
        hair: "#FEDA6B",
        nose: "#AE6C3C",
        eye: "#8E5B07",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "9",
      name: "Celine",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#1ED2FF",
        shirt: "#65EECF",
        skin: "#CB884D",
        hair: "#FEDA6B",
        nose: "#AE6C3C",
        eye: "#4F38B1",
        hairType: "straight",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "10",
      name: "Jane Holzman",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#27D36F",
        shirt: "#F8E71C",
        skin: "#8D4823",
        hair: "#FEF26B",
        nose: "#652E10",
        eye: "#0D9044",
        hairType: "curly",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "11",
      name: "Leroy Blevins",
      tagline: "",
      job: "Private Investigator",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#FF49DC",
        shirt: "#FEF26B",
        skin: "#FEF8F4",
        hair: "#842D0F",
        nose: "#FFEED5",
        eye: "#1FAD8F",
        hairType: "short",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "12",
      name: "Jackie",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#F55C23",
        shirt: "#F45E83",
        skin: "#8D4823",
        hair: "#D96D27",
        nose: "#652E10",
        eye: "#4F38B1",
        hairType: "straight",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "13",
      name: "Paige Danning",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#FF49DC",
        shirt: "#1FAD8F",
        skin: "#CB884D",
        hair: "#D96D27",
        nose: "#AE6C3C",
        eye: "#1FAD8F",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
    {
      id: "14",
      name: "Candy Poole",
      tagline: "",
      job: "",
      followerCount: 34,
      followingCount: 160,
      friend: false,
      colorScheme: {
        type: "human",
        circleBackground: "#F55C23",
        shirt: "#1FAD8F",
        skin: "#E8A569",
        hair: "#FFAA51",
        nose: "#CB884D",
        eye: "#47BEFF",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
    ,
    {
      id: "15",
      name: "Karly Simmons",
      tagline: "",
      job: "Model",
      followerCount: 1845,
      followingCount: 566,
      friend: true,
      colorScheme: {
        type: "human",
        circleBackground: "#F55C23",
        shirt: "#1FAD8F",
        skin: "#F9DEB7",
        hair: "#FEF26B",
        nose: "#F8CA8E",
        eye: "#50E3C2",
        hairType: "long",
        mouth: "#FF9DA9",
      },
    },
  ],
  all: function () {
    return this.characters;
  },
  filterList: function (str, key) {
    const isMatch = (element) =>
      element[key].toLowerCase().includes(str.toLowerCase());
    return str !== "" ? this.characters.filter(isMatch) : [];
  },
  friends: function () {
    const isMatch = (element) => element.friend;
    return this.characters.filter(isMatch);
  },
  get: function (id) {
    const isMatch = (element) => (element ? `${element.id}` === `${id}` : 0);
    return this.characters.find(isMatch);
  },
};

export default Characters;
