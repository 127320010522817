import React, { useEffect, useState } from "react";
import Results from "../results";

import ResultsArray from "../../apps-data/questions";

import Logo from "../images/question-logo.png";

const SearchBody = ({ showResults, searchTerm }) => {
  if (showResults) {
    const results = ResultsArray.get(searchTerm.toLowerCase());
    return <Results term={searchTerm} searchData={results} />;
  } else {
    return null;
  }
};

const Search = ({ searchTerm }) => {
  const [searchvalue, updateSearchvalue] = useState(searchTerm);
  const [showResults, shouldShowResults] = useState(false);

  const handleChange = (e) => {
    updateSearchvalue(e.target.value);
    shouldShowResults(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    shouldShowResults(true);
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `You searched for ${searchvalue}`;
    if (searchvalue !== "") {
      shouldShowResults(true);
    }
  }, [searchvalue]);

  const classes = showResults ? "app--body results" : "app--body text--center";
  return (
    <div className={classes}>
      <div className={"container"}>
        <img className={"logo"} src={Logo} alt="questions" />
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="message"
            placeholder="What are you seeking?"
            onChange={handleChange}
            value={searchvalue}
          />
          <button>ANSWER</button>
        </form>
        <SearchBody showResults={showResults} searchTerm={searchvalue} />
      </div>
    </div>
  );
};

export default Search;
