const bgColors = [
  "#3157B8",
  "#4A74E2",
  "#1ED2FF",
  "#50E3C2",
  "#27D36F",
  "#F5A623",
  "#F55C23",
  "#FF5554",
  "#FF49DC",
  "#EB8EFE",
  "#9861EC"
];

const shirt = [
  "#7CE5FF",
  "#B5FFEE",
  "#65EECF",
  "#1FAD8F",
  "#A1EB94",
  "#BEFF76",
  "#FEF26B",
  "#F8E71C",
  "#F45E83",
  "#D8BFFF"
];

const hairColor = [
  "#180701",
  "#441707",
  "#B83C12",
  "#D96D27",
  "#DA8C39",
  "#E5BE31",
  "#FEF26B",
  "#FEF26B",
  "#4D3F3A",
  "#999493"
];

const skinColor1 = [
  "#371807",
  "#663613",
  "#81471E",
  "#AE6C3C",
  "#CB884D",
  "#E8A569",
  "#F8CA8E",
  "#F9DEB7",
  "#FFEED5"
];

const skinColor2 = [
  "#371807",
  "#652E10",
  "#8D4823",
  "#BB683B",
  "#DA895D",
  "#F0AA83",
  "#FAC1A2",
  "#FBD7C3",
  "#FEE5DC"
];

const mouth = [
  "#FF9DA9",
  "#FF9DA9",
  "#FF9DA9",
  "#FF9DA9",
  "#FFACB6",
  "#E26171",
  "#F58390",
  "#FF9DA9",
  "#FF9DA9"
];

const eyeColor = [
  "#4A74E2",
  "#95B2FF",
  "#1FAD8F",
  "#0D9044",
  "#6F3E08",
  "#4F38B1",
  "#8E5B07",
  "#47BEFF",
  "#91C3DF",
  "#729E6A"
];

const hairType = ["short", "shortest", "curly", "straight", "long", "wavy"];

export {
  bgColors,
  shirt,
  hairColor,
  skinColor1,
  skinColor2,
  eyeColor,
  mouth,
  hairType
};
