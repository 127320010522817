import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as storyActions from "../../redux/actions";

import SceneArray from "../../data/murmur-ritual";

import "./scene.css";

import Headline from "./sceneComponents/headline.jsx";
import Content from "./sceneComponents/content.jsx";
import Links from "./sceneComponents/links.jsx";
import Sidebar from "./sceneComponents/sidebar.jsx";

const Scene = ({ history, match }) => {
  const dispatch = useDispatch();
  const [touchStart, setTouchStart] = useState([0, 0]);
  const [touchEnd, setTouchEnd] = useState(0);
  const scene = SceneArray.get(match.params.sceneId);
  const previousScenes = useSelector(
    (state) => state.storyReducer.previousScenes
  );

  const nextSceneNavigate = () => {
    dispatch(storyActions.addPreviousScene(scene.links[0]));
    history.push(`/scene/${scene.links[0]}`);
  };

  const previousSceneNavigate = () => {
    const previousScene = previousScenes[previousScenes.length - 2];
    dispatch(storyActions.removePreviousScene());
    history.push(`/scene/${previousScene || 0}`);
  };

  const handleSceneClick = (dir) => {
    if (dir === "prev") {
      previousSceneNavigate();
    }

    if (dir === "next") {
      nextSceneNavigate();
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart([e.targetTouches[0].clientX, e.targetTouches[0].clientY]);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (
      e.target.className !== "button--apps" &&
      e.target.className !== "arrow next-arrow" &&
      e.target.className !== "arrow prev-arrow"
    ) {
      const wHeight = window.innerHeight - 100;
      const [touchStartX, touchStartY] = touchStart;

      if (touchStartY < wHeight) {
        if (touchStartX - touchEnd > 150) {
          // do your stuff here for left swipe
          // go to next scene
          nextSceneNavigate();
        }

        if (touchStartX - touchEnd < -150) {
          // do your stuff here for right swipe
          // go to previous scene
          previousSceneNavigate();
        }
      }
    }
  };

  useEffect(() => {
    dispatch(storyActions.changeScene(match.params.sceneId));
  }, [match.params.sceneId]);

  if (!scene) {
    return <div>Sorry, but the scene was not found</div>;
  }

  return (
    <div
      className={`scene ${scene.type ? scene.type : "basic"}`}
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={(touchEndEvent) => handleTouchEnd(touchEndEvent)}
    >
      <div className="App">
        <div className="description">
          <Headline text={scene.headline} />
          <Content text={scene.content} author={scene.author} />
        </div>
        <div
          className="arrow prev-arrow"
          onClick={() => handleSceneClick("prev")}
        />
        {scene.links.length === 1 && (
          <div
            className="arrow next-arrow"
            onClick={() => handleSceneClick("next")}
          />
        )}
      </div>
      <div className="links">
        {scene.links.length > 1 && <Links links={scene.links} />}
      </div>
      <Sidebar link={match.params.sceneId} />
    </div>
  );
};

export default withRouter(Scene);
