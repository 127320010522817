import React from "react";
import AppWrapper from "../components/wrapper";
import "./index.css";

const AllSeeing = () => (
  <AppWrapper
    appType="app--allseeing"
    bodyClass="app--body"
    dark={false}
    headline={"All Seeing"}
    logo={false}
    params={true}
  >
    <div className={"app--allseeing app--body app--body2"}>
      <div className={"sphere sphere1"}>
        <div className={"sphere sphere2"} />
      </div>
    </div>
  </AppWrapper>
);

export default AllSeeing;
