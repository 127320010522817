import React from "react";
import AppWrapper from "../components/wrapper";
import "./index.css";

const Calendar = () => (
  <AppWrapper
    appType="app--calendar"
    bodyClass="app--body"
    dark={false}
    headline={"Calendar"}
    logo={false}
    params={true}
  >
    <div className={"app--calendar app--body"} />
  </AppWrapper>
);

export default Calendar;
