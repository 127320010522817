const Prologue = [
  {
    id: 0,
    link_text: "Begin",
    type: "quote",
    headline: "",
    content:
      "There comes a point when a dream becomes reality and reality becomes a dream.",
    author: "Frances Farmer",
    links: [1],
  },
  {
    id: 1,
    link_text: "Continue",
    headline: "PROLOGUE",
    content: "A foggy morning, just after dawn.",
    links: ["1a"],
  },
  {
    id: "1a",
    link_text: "Continue",
    headline: "PROLOGUE",
    content: "A woman jogs in Griffith Park, earbuds in and breathing hard.",
    links: ["1b"],
  },
  {
    id: "1b",
    link_text: "Continue",
    headline: "PROLOGUE",
    content:
      'She sees legs sticking out from under a sleeping bag in a ravine below the Hollywood sign. \n\n She moves the sleeping bag. \n\n Underneath is a young blond woman with a ring on her finger. The police, a detective, and a medical examiner arrive. A few feet from the body is a note.  \n\n"Doesn’t make any sense," says the uniform cop, as the sun comes up.',
    links: ["1c"],
  },
  {
    id: "1c",
    link_text: "Continue",
    headline: "Act 1",
    content: "",
    links: [2],
  },
];

export default Prologue;
