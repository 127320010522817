import React, { Component } from "react";

import { Header } from "../components";
import Image from "./elements/Image";

import "./index.css";

import Logo from "./images/logo@2x.png";
import Carol from "../apps-data/avatars/carol.png";
import Don from "../apps-data/avatars/don.png";
import Quint from "../apps-data/avatars/quint.png";
import Marco from "../apps-data/avatars/marco.png";

const users = [
  {
    BagP8RvTGl4wyTg96sXT: [
      {
        avatar: Carol,
        saying: "It's a Sunshine Day",
      },
    ],
    xvjUxyaqF3QITjSmB8iw: [
      {
        avatar: Don,
        saying: "Thanks for the memories",
      },
    ],
    ee2QRCkMvKEuZzAuy5XX: [
      {
        avatar: Quint,
        saying: "We're gonna need a bigger boat",
      },
    ],
    yb6iClGVhxgjyJ3IHWsZ: [
      {
        avatar: Marco,
        saying: "Have a drink!",
      },
    ],
  },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }
  componentDidMount() {
    let newState = [];
    db.collection("qliqk").onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // const { uri, likes, title } = doc.data();
        const post = {
          id: doc.id,
          data: doc.data(),
        };

        newState.push(post);
      });
      this.setState({ posts: newState });
    });
  }
  componentWillUnmount() {
    var unsubscribe = db.collection("qliqk").onSnapshot(function () {});
    // ...
    // Stop listening to changes
    unsubscribe();
  }
  render() {
    // console.log(this.state.posts);
    return (
      <div className="qliqk">
        <Header classes={null} dark={false} headline={""} params={true} />
        <div className="qliqk-header">
          <img className="qlick-logo" src={Logo} alt="LOGO" />
        </div>
        <section className="feed container">
          <div className="cards row wrap">
            {this.state.posts.map((post, index) => {
              return (
                <div className="card col-100" key={post.id}>
                  <div>
                    <div className="card__signature">
                      <img
                        className="card__signature-img"
                        src={users[0][post.data.userid][0].avatar}
                        alt={post.data.title}
                      />
                      <div>
                        <p className="card__name">{post.data.name}</p>
                        <span className="card__tag">
                          {users[0][post.data.userid][0].saying}
                        </span>
                      </div>
                    </div>
                    <div className="card__content">
                      <Image imageURL={post.data.uri} title={post.data.title} />
                      <p>{post.data.message}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}
export default Home;
