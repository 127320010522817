import React from "react";
import { Header } from "../components";

import "./index.css";

const Photos = (props) => {
  return (
    <div className="app--photos">
      <Header
        logo={false}
        dark={false}
        headline={"Photos"}
        params={props.match.params}
      />
      <div className={"app--body"} />
    </div>
  );
};

export default Photos;
