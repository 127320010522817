import React from "react";

import AppWrapper from "../components/wrapper";
import { PostsList, SpacebookHeader, UserImage } from "./elements";

import CharacterArray from "../apps-data/characters";
import PostsArray from "../apps-data/posts";

import "./index.css";

const Profile = (props) => {
  const characterId = props.match.params.userId;
  const character = CharacterArray.get(characterId);
  const posts = PostsArray.userPosts(characterId);
  return (
    <AppWrapper
      appType="spacebook"
      dark={false}
      headline={""}
      params={props.location}
    >
      <React.Fragment>
        <SpacebookHeader />
        <div>
          <section className="feed">
            <div className="cards">
              <div className="card">
                <div className="card__signature row">
                  <div className="col-25">
                    <UserImage data={character} />
                    <span className="card__name">{character.name}</span>
                  </div>
                  <div className="col-75">
                    <div className="row">
                      <div className="col-33">
                        Posts
                        <br />
                        {posts.length}
                      </div>
                      <div className="col-33">
                        Followers
                        <br />
                        {character.followerCount.toLocaleString()}
                      </div>
                      <div className="col-33">
                        Following
                        <br />
                        {character.followingCount.toLocaleString()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-100">
                        {/* <p>{character.tagline}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {character.friend && <PostsList posts={posts} />}
            {!character.friend && <button>Request friendship</button>}
          </section>
        </div>
      </React.Fragment>
    </AppWrapper>
  );
};

export default Profile;
